<script lang="ts">
  export let id: string | undefined = undefined
  export let error = false
  export let name: string
  export let placeholder: string
  export let type: svelte.JSX.HTMLAttributes<HTMLInputElement>['type'] = 'text'
  export let value = ''

  const handleChange: svelte.JSX.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    value = event.currentTarget.value
  }
</script>

<input
  class="block bg-transparent text-black lg:text-white placeholder-black lg:placeholder-white border-black lg:border-white placeholder-opacity-40 lg:placeholder-opacity-40 border-b border-opacity-50 lg:border-opacity-50 active:border-opacity-100 focus:border-opacity-100 hover:border-opacity-80 max-w-full w-60 py-2 px-1 outline-none"
  class:error
  on:change={handleChange}
  {id}
  {name}
  {placeholder}
  {type}
  {value}
/>

<style>
  .error {
    border-color: rgb(248, 113, 113);
  }
</style>
