<script lang="ts">
  import { createEventDispatcher } from 'svelte'

  import type { Player } from '../data/players'
  import Instagram from '../icons/Instagram.svelte'
  import { canSubmit, didSubmit } from '../utils/session';
  import { isValidEmail } from '../utils/validation';
  import Checkbox from './Checkbox.svelte';
  import Input from './Input.svelte'
  import ScrollHint from './ScrollHint.svelte'
  import SpecialButton from './SpecialButton.svelte'

  export let player: Player

  const dispatch = createEventDispatcher()

  let nameValue = ''
  let emailValue = ''
  let acceptTermsChecked = false

  let nameError = false
  let emailError = false
  let emailFormatError = false
  let acceptTermsError = false

  let state: 'idle' | 'submitting' | 'submitError' | 'submitSuccess' = 'idle'

  function handleToggleAcceptTerms() {
    acceptTermsChecked = !acceptTermsChecked
  }

  function handleReset() {
    dispatch('reset')
  }

  const handleSubmit: svelte.JSX.FormEventHandler<HTMLFormElement> = (
    event
  ) => {
    event.preventDefault()

    nameError = !nameValue
    emailError = !emailValue
    emailFormatError = !isValidEmail(emailValue)
    acceptTermsError = !acceptTermsChecked

    if (nameError || emailError || emailFormatError || acceptTermsError) {
      return
    }

    // Check if hidden field is filled out (basic spam prevention)
    const inputs = event.currentTarget.elements
    const hiddenInput = inputs['doldis']
    if (hiddenInput.value) {
      state = 'submitError'
      return
    }

    state = 'submitting'

    if (!canSubmit()) {
      state = 'submitError'
      return;
    }

    fetch('/api/competition', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name: nameValue, email: emailValue, player: player.name })
    }).then((response) => {
      if (response.status >= 400) {
        throw new Error(response.statusText);
      }

      if (response.status === 304) {
        return {};
      }

      return response.json();
    })
      .then((_data) => {
        state = 'submitSuccess'
        didSubmit()
      }).catch((_error) => {
        state = 'submitError'
      })
  }
</script>

<ScrollHint />

<div class="wrapper grid grid-cols-1 gap-8 lg:grid-cols-2">
  <div class="block lg:hidden space-y-1 text-center">
    <h5 class="text-4xl font-medium uppercase">{player.name}</h5>
    <span class="block text-xl font-medium uppercase">{player.team}</span>
  </div>
  <div class="img flex flex-col items-center space-y-8">
    <img
      class="max-w-full"
      src={player.img}
      alt={player.name}
      width="416"
      height="505"
    />
    <div class="hidden lg:block">
      <SpecialButton on:click={handleReset}>Gör testet igen</SpecialButton>
    </div>
  </div>
  <div>
    <div class="hidden lg:block lg:mb-8 space-y-1">
      <h5 class="text-4xl font-medium uppercase">{player.name}</h5>
      <span class="block text-xl font-medium uppercase">{player.team}</span>
    </div>
    <div>
      <p class="leading-relaxed">{player.description}</p>
      <div class="space-y-8 mt-8">
        {#if player.instagram}
          <a
            class="flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-4"
            href={`https://www.instagram.com/${player.instagram}/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram size="48" />
            <span class="text-sm text-white text-opacity-75">
              Följ {player.name}!
            </span>
          </a>
        {/if}
        <div class="hidden lg:block h-px bg-white" />
        <form
          class="bg-white text-black -mx-4 px-6 py-8 lg:mx-0 lg:p-0 lg:bg-transparent lg:text-white space-y-4 flex flex-col items-center lg:block"
          on:submit={handleSubmit}
        >
          <h5 class="text-lg font-medium uppercase">
            Vinn {player.genitiveCase} matchtröja
          </h5>
          {#if state === 'submitSuccess'}
            <div>Tack!</div>
          {:else if state === 'submitError'}
            <div>Något gick fel, försök igen senare.</div>
          {:else}
            <Input
              error={nameError}
              name="name"
              placeholder="Namn"
              bind:value={nameValue}
            />
            <Input
              error={emailError || emailFormatError}
              name="email"
              placeholder="E-post"
              bind:value={emailValue}
            />
            {#if !emailError && emailFormatError}
              <span class="text-red-600">Ange en giltig e-postadress</span>
            {/if}
            <Checkbox
              error={acceptTermsError && 'Du behöver godkänna villkoren'}
              name="acceptTerms"
              id="acceptTermsCheckbox"
              value="acceptTerms"
              checked={acceptTermsChecked}
              on:change={handleToggleAcceptTerms}
            >
              Jag godkänner <a class="underline" href="/pdf/tavling-villkor.pdf" rel="noopener noreferrer" target="_blank">tävlingsvillkoren</a>
            </Checkbox>
            <input name="doldis" class="hidden">
            <div class="pt-4">
              <button
                class="font-medium bg-black lg:bg-white text-white lg:text-black rounded-lg py-2 px-8 uppercase text-sm"
                class:submitting={state === 'submitting'}
                disabled={state === 'submitting'}
              >
                {state === 'submitting' ? 'Skickar...' : 'Skicka'}
              </button>
            </div>
          {/if}
        </form>
      </div>
      <div class="mt-12 flex flex-col items-center lg:hidden">
        <SpecialButton on:click={handleReset}>Gör testet igen</SpecialButton>
      </div>
      <div class="mt-20 flex flex-col items-center lg:items-end">
        <img
          src="/logos/partnerlogov2.webp"
          alt="logotyper av våra samarbetspartners"
          class="max-w-full w-[282px]"
        />
      </div>
    </div>
  </div>
</div>

<style>
  .submitting {
    opacity: 0.6;
  }
</style>
