import { answersByPlayer } from './answers-by-player';
export const positionAlternatives = {
    forward: 'Anfallare',
    'wing-back': 'Ytterback',
    'centre-back': 'Mittback',
    keeper: 'Målvakt',
    'centre-midfield': 'Innermitt',
    'wide-midfield': 'Yttermitt',
};
export function getPositionDisplayName(position) {
    switch (position) {
        case 'keeper':
            return 'Målvakt';
        case 'centre-back':
            return 'Mittback';
        case 'wing-back':
            return 'Ytterback';
        case 'centre-midfield':
            return 'Innermitt';
        case 'wide-midfield':
            return 'Yttermitt';
        case 'forward':
            return 'Anfallare/forward';
    }
}
export const players = [
    {
        name: 'Jennifer Falk',
        genitiveCase: 'Jennifers',
        position: 'keeper',
        img: '/players/Hacken_falk.webp',
        team: 'BK Häcken',
        description: 'Grattis! Du är mest lik Jennifer Falk, målvakt i BK Häcken. Falk är en målvakt med bra reflexer som gillar att spela långt ut i planen och att vara delaktig. Hon har många strängar på sin lyra och har tagit SM-guld i tre olika sporter: futsal, tennis och fotboll.',
        instagram: 'jfalk_',
    },
    {
        name: 'Elin Rubensson',
        genitiveCase: 'Elins',
        position: 'centre-midfield',
        img: '/players/Hacken_rubensson.webp',
        team: 'BK Häcken',
        description: 'Grattis! Du är mest lik Elin Rubensson, innermitt i BK Häcken. Rubensson är en lojal mittfältare som gillar att sätta sina lagkamrater i bra situationer. Hon har bra speluppfattning och bra passningsfot. Elin Rubensson har varit med och tagit både OS-silver och VM-brons. Elin är dessutom certifierad inredare och driver egen designstudio.',
        instagram: 'erubensson',
    },
    {
        name: 'Josefine Rybrink',
        genitiveCase: 'Josefines',
        position: 'centre-back',
        img: '/players/KDFF_rybrink.webp',
        team: 'Kristianstad DFF',
        description: 'Grattis! Du är mest lik Josefine Rybrink, mittback i Kristianstad DFF. Rybrink är lugn och trygg med bollen. Hon är en grym passningsspelare med bra spelförståelse. Som 14-åring var Josefine Rybrink rankad 32a i Sverige i tennis.',
        instagram: 'josefinerybrink',
    },
    {
        name: 'Mimmi Larsson',
        genitiveCase: 'Mimmis',
        position: 'forward',
        img: '/players/FCR_larsson.webp',
        team: 'FC Rosengård',
        description: 'Grattis! Du är mest lik Mimmi Larsson, anfallare i FC Rosengård. Larsson är snabb, stark och målfarlig och ger alltid 100%! Hon var med och tog VM-brons med svenska landslaget i Frankrike 2019.',
        instagram: 'mimmiilarsson',
    },
    {
        name: 'Jenna Hellstrom',
        genitiveCase: 'Jennas',
        position: 'wide-midfield',
        img: '/players/Orebro_jenna.webp',
        team: 'KIF Örebro',
        description: 'Grattis! Du är mest lik Jenna Hellstrom, yttermitt i KIF Örebro. Hellstrom är en fysisk, snabb och noggrann spelare. Denna kanadensiska landslagsspelare vill alltid hjälpa laget att bli bättre och pusha alla, inklusive sig själv, till nästa nivå.',
        instagram: 'jennahellstrom',
    },
    {
        name: 'Loreta Kullashi',
        genitiveCase: 'Loretas',
        position: 'forward',
        img: '/players/FCR_kullashi.webp',
        team: 'FC Rosengård',
        description: 'Grattis! Du är mest lik Loreta Kullashi, anfallare i FC Rosengård. Kullashi är en målfarlig och teknisk spelare som är grym i 1-mot-1-situationer. Hon spelar med landslaget och gjorde inte mindre än två mål vid sin landslagsdebut.',
        instagram: 'loretakullashi',
    },
    {
        name: 'Madelen Janogy',
        genitiveCase: 'Madelens',
        position: 'forward',
        img: '/players/Hammarby_janogy.webp',
        team: 'Hammarby',
        description: 'Grattis! Du är mest lik Madelen Janogy, anfallare i Hammarby IF. Janogy är en kreativ spelare som gillar att utmana och ta sig förbi sina motståndare. Janogy har ett SM-guld i bagaget och spelar i landslaget.',
        instagram: 'maddejanogy',
    },
    {
        name: 'Nellie Lilja',
        genitiveCase: 'Nellies',
        position: 'centre-midfield',
        img: '/players/DIF_lilja.webp',
        team: 'Djurgårdens IF',
        description: 'Grattis! Du är mest lik Nellie Lilja, innermitt i Djurgårdens IF. Lilja är en hungrig mittfältare som hela tiden vill utvecklas som fotbollsspelare och ledare. Hon vill hjälpa laget framåt och vara en trygghet som spelare och ledare på innermittfältet. Vad gäller styrkor utanför plan finns ingen som slår Nellie Lilja i jägarvila.',
        instagram: 'nellielilja_',
    },
    {
        name: 'Honoka Hayashi',
        genitiveCase: 'Honokas',
        position: 'centre-midfield',
        img: '/players/AIK_hayashi.webp',
        team: 'AIK',
        description: 'Grattis! Du är mest lik Honoka Hayashi, mittfältare i AIK. Hayashi är en spelmotor som är bra på fasta situationer. Hon har bra teknik och driv med bollen. Honoka Hayashi älskar skottövningar, choklad och ingen slår henne i att jobba hårt.',
        instagram: 'hayashi.0123',
    },
    {
        name: 'Caroline Seger',
        genitiveCase: 'Carolines',
        position: 'centre-midfield',
        img: '/players/FCR_seger.webp',
        team: 'FC Rosengård',
        description: 'Grattis! Du är mest lik Caroline Seger, mittfältare i FC Rosengård. Seger är lagkapten, en ledare och spelfördelare. Hennes största styrka är passningsspelet och att hon är trygg med boll. Caroline Seger spelar dessutom gärna golf och är – om man ska tro före detta fotbollsstjärnan Therese Sjögran – mycket duktig på golfbanan.',
        instagram: 'segercaroline',
    },
    {
        name: 'Olivia Scough',
        genitiveCase: 'Olivias',
        position: 'wide-midfield',
        img: '/players/FCR_schough.webp',
        team: 'FC Rosengård',
        description: 'Grattis! Du är mest lik Olivia Schough, yttermitt i FC Rosengård. Schough är är en löpstark spelare med mycket energi. Hon är bra i 1-mot-1-situationer och en vass avslutare. Vad många kanske inte vet är att Olivia Schough har en fantastiskt sångröst och uppträder gärna tillsammans med sina syskon, som också de är musikaliska.',
        instagram: 'oliviaschough',
    },
    {
        name: 'Nilla Fischer',
        genitiveCase: 'Nillas',
        position: 'centre-back',
        img: '/players/Linkoping_fischer.webp',
        team: 'Linköpings FC',
        description: 'Grattis! Du är mest lik Nilla Fischer, mittback i Linköpings FC. Fischer är en positionsstark spelare med en lång fotbollskarriär bakom sig. Hon har varit med i landslaget sedan 2001 och blivit utsedd till Sveriges bästa fotbollsspelare.',
        instagram: 'fischernilla',
    },
    {
        name: 'Lisa Klinga',
        genitiveCase: 'Lisas',
        position: 'wing-back',
        img: '/players/Vittsjo_lisa.webp',
        team: 'Vittsjö GIK',
        description: 'Grattis! Du är mest lik Lisa Klinga, ytterback i Vittsjö GIK. Det som kännetecknar Klinga är att hon är en offensiv back, med en aldrig sinande ork och en spelare som offrar sig i varje duell. Dessutom har hon tagit silver i Champions League och har precis blivit mamma till en liten Falke.',
        instagram: 'lisaklinga',
    },
    // ---
    {
        name: 'Amalie Vansgaard',
        genitiveCase: 'Amalies',
        position: 'forward',
        img: '/players/Linkoping_amalie.webp',
        team: 'Linköping FC',
        description: 'Grattis! Du är mest lik Amalie Vangsgaard, dansk anfallare i Linköpings FC. Hon värvades dit under sommaren 2021 – då som wingback – men fick sedan hoppa in som anfallare. Det blev minst sagt succé och idag är hon en av lagets viktigaste spelare som toppar skytteligan.',
        instagram: 'amaliejvangsgaard',
    },
    {
        name: 'Filippa Curmark',
        genitiveCase: 'Filippas',
        position: 'centre-midfield',
        img: '/players/Hacken_filippa.webp',
        team: 'BK Häcken',
        description: 'Grattis! Du är mest lik Filippa Curman, BK Häckens nya lagkapten. Hon är en skicklig spelare och smart bollvinnare. Hon beskriver sig som en energisk, driven och impulsiv person. Samt självutnämnd mästare i bicepscurl.',
        instagram: 'filippacurmark',
    },
    {
        name: 'Mia Jalkerud',
        genitiveCase: 'Mias',
        position: 'forward',
        img: '/players/Eskilstuna_mia.webp',
        team: 'Eskilstuna United',
        description: 'Grattis! Du är mest lik Mia Jalkerud, anfallare i Eskilstuna United. Mia är en pålitlig målmaskin med lång erfarenhet. Hon har snabbt blivit en viktig spelare och EU-profil.',
        instagram: 'miajalkerud',
    },
    {
        name: 'Matilda Plan',
        genitiveCase: 'Matildas',
        position: 'centre-back',
        img: '/players/Eskilstuna_matilda.webp',
        team: 'Eskilstuna United',
        description: 'Grattis! Du är mest lik Matilda Plan, lagkapten i Eskilstuna United. Matilda är en elitidrottare i dubbel bemärkelse. Utöver sin fenomenala fotbollskarriär är hon även före detta bandyspelare med VM-guld i bagaget.',
        instagram: 'matildaplan',
    },
    {
        name: 'Tabby Tindell',
        genitiveCase: 'Tabbys',
        position: 'forward',
        img: '/players/KDFF_tabby.webp',
        team: 'Kristianstad DFF',
        description: 'Grattis! Du är mest lik Tabby Tindell, amerikansk anfallsstjärna i Kristianstad DFF. Hon är en målskytt av rang som gör allt för lagets framgång. Förutom kärleken till fotboll slår Tabbys hjärta extra hårt för kaffe.',
        instagram: 'tabby_tindell',
    },
    {
        name: 'Évelyne Viens',
        genitiveCase: 'Evelynes',
        position: 'forward',
        img: '/players/KDFF_evelyne.webp',
        team: 'Kristianstad DFF',
        description: 'Grattis! Du är mest lik Évelyne Viens, kanadensisk anfallare i Kristianstad DFF. Évelyne är en ambitiös spelfördelare som levererar mål efter mål. Hon ligger just nu i toppen av skytteligan. År 2020 kammade Évelyne tillsammans med Kanadas damlandslag hem OS-guld för deras otroliga insats.',
        instagram: 'evelyneviens',
    },
    {
        name: 'Julia Walentowicz',
        genitiveCase: 'Julias',
        position: 'wing-back',
        img: '/players/DIF_julia.webp',
        team: 'Djurgårdens IF',
        description: 'Grattis! Du är mest lik Julia Walentowicz, ytterback i Djurgårdens IF. Julia är en egenfostrad, karismatisk spelare med stort hjärta för sitt lag. Hon har en enorm styrka och kraft i det defensiva arbetet.',
        instagram: 'juliawalentowicz',
    },
    {
        name: 'Agnes Nyberg',
        genitiveCase: 'Agnes',
        position: 'centre-midfield',
        img: '/players/AIK_agnes.webp',
        team: 'AIK',
        description: 'Grattis! Du är mest lik Agnes Nyberg, mittfältare i AIK. Agnes är en defensiv, solid spelfördelare med en bra blick för spelet. Hon är stark i dueller och har en enorm spelförståelse.',
        instagram: 'agneesnyberg',
    },
    {
        name: 'Alice Carlsson',
        genitiveCase: 'Alice',
        position: 'centre-back',
        img: '/players/Hammarby_alice.webp',
        team: 'Hammarby',
        description: 'Grattis! Du är mest lik Alice Carlsson, mittback i Hammarby. Alice är en placerings- och passningsskicklig spelare som även bär lagets kaptensbindel. Alice ser sig själv som en smart fotbollsspelare med bra högerfot.',
        instagram: 'carlssonalice',
    },
    {
        name: 'Elli Pikkujämsä',
        genitiveCase: 'Ellis',
        position: 'centre-back',
        img: '/players/Orebro_elli.webp',
        team: 'Örebro',
        description: 'Grattis! Du är mest lik Elli Pikkujämsä, finsk back i Örebro. Elli har en viktig roll i truppen och beskrivs som en stark, defensiv spelare med en passningsfot av hög kvalitet. Elli spelar även för det finska landslaget.',
        instagram: 'ellipikkujamsa',
    },
    {
        name: 'Paige Culver',
        genitiveCase: "Paige's",
        position: 'centre-back',
        img: '/players/Kalmar_paige.webp',
        team: 'IFK Kalmar',
        description: 'Grattis! Du är mest lik Paige Culver, kanadensisk mittback i IFK Kalmar. Paige har en mental styrka som tar henne igenom utmaningar både på och utanför planen. Hennes nästa karriärsmål är att även spela för Kanadas landslag.',
        instagram: 'culver.paige',
    },
    {
        name: 'Katrina Guillou',
        genitiveCase: 'Katrinas',
        position: 'centre-midfield',
        img: '/players/Pitea_katrina.webp',
        team: 'Piteå',
        description: 'Grattis! Du är mest lik Katrina Guillou, amerikansk anfallare i Piteå. Katrina är född i Washington DC och representerar Filipinernas damlandslag. I april 2022 gjorde Katrina sitt förstainternationella hattrick i en vänskapsmatch mot Fiji. I Piteå är Katrina i bra målform och anses vara en nyckelspelare i laget.',
        instagram: 'guillouk',
    },
    {
        name: 'Lisa Björk',
        genitiveCase: 'Lisas',
        position: 'forward',
        img: '/players/Umea_lisa.webp',
        team: 'Umeå',
        description: 'Grattis! Du är mest lik Lisa Björk, skicklig ytteranfallare i Umeå. Lisa levererar mål och assist på löpande band och är en bidragande faktor till det höga speltempot. Hon en stark och skicklig målskytt med goda framtidsutsikter.',
        instagram: 'lisabj0rk',
    },
    // {
    //   name: 'Mathilda Johansson Prakt',
    //   team: 'BP',
    //   description:
    //     'Grattis! Du är mest lik Mathilda Johansson Prakt, anfallare i BP.Mathilda är en erfaren spelare som är riktigt vass i offensiven. Honär även stark i dueller och i motståndarens straffområde där honofta levererar mål.',
    // },
];
export function getPlayersByPosition(position) {
    return players.filter((player) => player.position === position);
}
export function getPlayerByName(name) {
    return players.find((player) => player.name === name);
}
export function getTopPlayerBasedOnAnswers(position, answers) {
    const playersWithAnswers = getPlayersByPosition(position).map((player) => {
        return Object.assign(Object.assign({}, player), { answers: answersByPlayer[player.name] });
    });
    const playerScores = {};
    for (const { questionId, alternativeKey } of answers) {
        for (const player of playersWithAnswers) {
            if (typeof player.answers[questionId] === 'undefined') {
                continue;
            }
            if (typeof playerScores[player.name] === 'undefined') {
                playerScores[player.name] = 0;
            }
            playerScores[player.name] += player.answers[questionId][alternativeKey];
        }
    }
    const playersSortedByScoreDesc = Object.entries(playerScores).sort((a, b) => {
        if (a[1] < b[1]) {
            return 1;
        }
        if (a[1] > b[1]) {
            return -1;
        }
        return 0;
    });
    const [, topScore] = playersSortedByScoreDesc[0];
    const topPlayers = playersSortedByScoreDesc.filter(([, score]) => topScore === score);
    let topPlayerName;
    // There may be more than 1 player with equal top scores,
    // in that case we want to select a random one among those
    if (topPlayers.length > 1) {
        ;
        [topPlayerName] = getRandomArrayElement(topPlayers);
    }
    else {
        ;
        [topPlayerName] = topPlayers[0];
    }
    return getPlayerByName(topPlayerName);
}
function getRandomArrayElement(items) {
    return items[Math.floor(Math.random() * items.length)];
}
