const LAST_SUBMIT_KEY = 'spelarkompassen_lsk';
const SUBMIT_DELAY_MILLISECONDS = 5 * 1000;
export function canSubmit() {
    var _a;
    const lastSubmit = (_a = window.sessionStorage) === null || _a === void 0 ? void 0 : _a.getItem(LAST_SUBMIT_KEY);
    if (!lastSubmit) {
        return true;
    }
    const difference = Date.now() - new Date(lastSubmit).getTime();
    return !difference || difference > SUBMIT_DELAY_MILLISECONDS;
}
export function didSubmit() {
    var _a;
    (_a = window.sessionStorage) === null || _a === void 0 ? void 0 : _a.setItem(LAST_SUBMIT_KEY, new Date().toISOString());
}
