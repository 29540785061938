<script lang="ts">
  import { onMount } from 'svelte'
  import SpecialButton from './SpecialButton.svelte'

  const STORAGE_KEY = 'bannerDismissed'

  let showBanner = false

  onMount(() => {
    if (window.localStorage.getItem(STORAGE_KEY)) {
      return
    }

    showBanner = true
  })

  function handleClose() {
    showBanner = false
    window.localStorage.setItem(STORAGE_KEY, 'true')
  }
</script>

{#if showBanner}
  <div
    class="fixed left-0 bottom-0 w-full bg-black text-white text-sm px-8 py-16"
  >
    <div
      class="flex flex-col space-y-8 md:space-y-0 md:flex-row md:items-center"
    >
      <div class="w-full">
        <div class="max-w-3xl">
          <p>
            Vi använder cookies för att tillhandahålla våra tjänster samt för
            mätnings- och analyssyften.
          </p>
          <p class="mt-2">
            Genom att använda vår webbplats och våra tjänster godkänner du
            användningen av cookies på det sätt som beskrivs i vår{' '}
            <a
              class="underline"
              href="/#cookie"
              rel="noopener noreferrer"
              target="_blank">policy för cookies</a
            >.
          </p>
        </div>
      </div>
      <div class="text-center md:text-left md:flex-shrink-0 md:ml-8 lg:ml-16">
        <SpecialButton on:click={handleClose} size="small">Stäng</SpecialButton>
      </div>
    </div>
  </div>
{/if}
