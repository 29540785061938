<script lang="ts">
  // import { players } from './data/players'
  import { onMount } from 'svelte'
  import type { Player } from './data/players'
  import { answers } from './stores/answers-store'
  import PlayerProfile from './components/PlayerProfile.svelte'
  import PlayerProfileWithForm from './components/PlayerProfileWithForm.svelte'
  import Questions from './components/Questions.svelte'
  import CookieNotice from './components/CookieNotice.svelte'
  import { Tracking } from './utils/tracking'
  import CookieInfo from './components/CookieInfo.svelte'

  export let showCookieInfo: boolean
  export let showJerseyForm: boolean

  onMount(() => {
    Tracking.initialize()
  })

  let appState: 'questions' | 'showPlayer' = 'questions'
  let selectedPlayer: Player | undefined
  // let appState: 'questions' | 'showPlayer' = 'showPlayer'
  // let selectedPlayer: Player | undefined = players[24]

  function handleQuestionsResult(player: Player) {
    Tracking.event('Quiz', 'Finished', { label: player.name })
    Tracking.pageviewOnce('/quiz/result')
    selectedPlayer = player
    appState = 'showPlayer'
    document.body.scrollIntoView()
  }

  function handleReset() {
    answers.reset()
    appState = 'questions'
    selectedPlayer = undefined
    document.body.scrollIntoView()
  }
</script>

{#if showCookieInfo}
  <CookieInfo />
{:else}
  <main class="p-4">
    <div>
      <img
        class="w-16 h-16 lg:w-40 lg:h-40"
        src="/logos/damallsvenskan.webp"
        alt="logotyp för damallsvenskan"
      />
    </div>
    <div class="py-6 w-full max-w-5xl mx-auto">
      {#if appState === 'questions'}
        <Questions
          isJerseyCompetitionActive={showJerseyForm}
          onResult={handleQuestionsResult}
        />
      {:else if showJerseyForm}
        <PlayerProfileWithForm on:reset={handleReset} player={selectedPlayer} />
      {:else}
        <PlayerProfile on:reset={handleReset} player={selectedPlayer} />
      {/if}
    </div>
  </main>

  <CookieNotice />
{/if}

<style global>
  .highlight {
    position: relative;
  }

  .highlight::before {
    content: '';
    position: absolute;
    top: -0.5rem;
    left: -1rem;
    width: calc(100% + 2rem);
    height: calc(100% + 1rem);
    background-color: rgb(251, 191, 36);
    opacity: 0.5;
    animation: fade 1600ms ease-out;
  }

  @keyframes fade {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 0;
    }
  }
</style>
