export var Tracking;
(function (Tracking) {
    const GA_ID = 'G-VLTM3Y6DEH';
    const trackedPageviews = {};
    function send(...args) {
        if (typeof window.gtag === 'function') {
            window.gtag(...args);
        }
    }
    function initialize() {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function gtag() {
            window.dataLayer.push(arguments);
        };
        send('js', new Date());
        send('config', GA_ID);
    }
    Tracking.initialize = initialize;
    function event(category, action, { label, value } = {}) {
        const eventOptions = {
            event_category: category,
            event_label: label,
            value: value,
        };
        send('event', action, eventOptions);
    }
    Tracking.event = event;
    function pageview(pagePath, pageTitle) {
        const eventOptions = {
            page_path: `${pagePath}.virt`,
            page_title: pageTitle,
            send_to: GA_ID,
        };
        send('event', 'page_view', eventOptions);
    }
    Tracking.pageview = pageview;
    function pageviewOnce(pagePath, pageTitle) {
        if (trackedPageviews[pagePath]) {
            return;
        }
        trackedPageviews[pagePath] = true;
        pageview(pagePath, pageTitle);
    }
    Tracking.pageviewOnce = pageviewOnce;
})(Tracking || (Tracking = {}));
