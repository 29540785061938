<script>
  import SpecialButton from './SpecialButton.svelte'

  function handleClick() {
    window.location.assign('/')
  }
</script>

<div class="max-w-3xl mx-auto my-16 space-y-8 px-4 lg:px-0">
  <div>
    <h5 class="mb-2 font-medium text-xl">Så använder vi kakor</h5>
    <p class="mb-4">
      En kaka, eller cookie, är en textfil som lagras på din dator, mobiltelefon
      eller annan enhet när du besöker en webbplats. Kakor används för att känna
      igen din enhet nästa gång som du besöker webbplatsen. Det finns även andra
      liknande tekniker, t.ex. pixeltaggar (genomskinliga bilder som kan finnas
      i e-post som skickas till dig) och webblagring (som kan användas för att
      lagra information i din enhets webbläsare). På den här sidan kallar vi
      alla dessa typer av tekniker för ”kakor”.
    </p>
    <p class="mb-2">Vi använder både sessionskakor och beständiga kakor:</p>
    <ul class="list-disc list-inside space-y-1 mb-6">
      <li>
        Sessionskakor lagras under den tid som du använder webbplatsen och
        raderas när du stänger ner din webbläsare.
      </li>
      <li>
        Beständiga kakor lagras på din enhet under en viss tid eller till dess
        att du tar bort dem.
      </li>
    </ul>
    <p class="mb-2">Vi använder både förstapartskakor och tredjepartskakor:</p>
    <ul class="list-disc list-inside space-y-1 mb-6">
      <li>Förstapartskakor ställs in av webbplatsen som du besöker.</li>
      <li>
        Tredjepartskakor ställs in av någon annan än webbplatsen som du besöker.
      </li>
    </ul>
  </div>
  <div class="text-center">
    <SpecialButton on:click={handleClick}>Tillbaka</SpecialButton>
  </div>
</div>
