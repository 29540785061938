// const playerNames = [
//   'Jennifer Falk',
//   'Guro Petterssen',
//   'Elin Rubensson',
//   'Stina Blackstenius',
//   'Julia Zigiotti Olme',
//   'Josefine Rybrink',
//   'Stephanie Labbé',
//   'Mimmi Larsson',
//   'Jenna Hellstrom',
//   'Loreta Kullashi',
//   'Madelen Janogy',
//   'Rosa Kafaji',
//   'Nellie Lilja',
//   'Elin Landström',
//   'Honoka Hayashi',
//   'Caroline Seger',
//   'Olivia Scough',
//   'Ronja Aronsson',
//   'Nilla Fischer',
//   'Elin Karlsson',
//   'Lisa Klinga',
//   'Fernanda Da Silva',
// ] as const
const playerNames = [
    'Jennifer Falk',
    'Elin Rubensson',
    'Josefine Rybrink',
    'Jenna Hellstrom',
    'Loreta Kullashi',
    'Madelen Janogy',
    'Mimmi Larsson',
    'Nellie Lilja',
    'Honoka Hayashi',
    'Caroline Seger',
    'Olivia Scough',
    'Nilla Fischer',
    'Lisa Klinga',
    'Mia Jalkerud',
    'Matilda Plan',
    'Tabby Tindell',
    'Évelyne Viens',
    'Julia Walentowicz',
    'Elli Pikkujämsä',
    'Agnes Nyberg',
    'Katrina Guillou',
    'Filippa Curmark',
    'Alice Carlsson',
    'Paige Culver',
    'Lisa Björk',
    'Amalie Vansgaard',
];
// export const answersByPlayer: AnswersByPlayer = {
//   'Jennifer Falk': {
//     goals: {
//       A: 6,
//       B: 5,
//       C: 4,
//       D: 3,
//       E: 2,
//       F: 1,
//     },
//     strength: {
//       A: 6,
//       B: 5,
//       C: 1,
//       D: 3,
//       E: 4,
//       F: 2,
//     },
//     formerPlayer: {
//       A: 2,
//       B: 1,
//       C: 5,
//       D: 6,
//       E: 3,
//       F: 4,
//     },
//     halfwayLine: {
//       A: 5,
//       B: 6,
//       C: 1,
//       D: 3,
//       E: 2,
//       F: 4,
//     },
//     afterTackle: {
//       A: 1,
//       B: 5,
//       C: 2,
//       D: 4,
//       E: 6,
//       F: 3,
//     },
//     breakaway: {
//       A: 6,
//       B: 1,
//       C: 3,
//       D: 2,
//       E: 4,
//       F: 5,
//     },
//     corner: {
//       A: 5,
//       B: 3,
//       C: 2,
//       D: 1,
//       E: 6,
//       F: 4,
//     },
//   },
//   'Guro Petterssen': {
//     goals: {
//       A: 5,
//       B: 6,
//       C: 4,
//       D: 3,
//       E: 2,
//       F: 1,
//     },
//     strength: {
//       A: 5,
//       B: 3,
//       C: 4,
//       D: 1,
//       E: 6,
//       F: 2,
//     },
//     formerPlayer: {
//       A: 3,
//       B: 1,
//       C: 6,
//       D: 5,
//       E: 4,
//       F: 2,
//     },
//     halfwayLine: {
//       A: 4,
//       B: 5,
//       C: 1,
//       D: 3,
//       E: 6,
//       F: 2,
//     },
//     afterTackle: {
//       A: 4,
//       B: 5,
//       C: 2,
//       D: 6,
//       E: 3,
//       F: 1,
//     },
//     breakaway: {
//       A: 6,
//       B: 1,
//       C: 5,
//       D: 2,
//       E: 3,
//       F: 4,
//     },
//     corner: {
//       A: 2,
//       B: 4,
//       C: 3,
//       D: 1,
//       E: 6,
//       F: 5,
//     },
//   },
//   'Elin Rubensson': {
//     goals: {
//       A: 2,
//       B: 4,
//       C: 6,
//       D: 5,
//       E: 3,
//       F: 1,
//     },
//     strength: {
//       A: 1,
//       B: 5,
//       C: 3,
//       D: 2,
//       E: 6,
//       F: 4,
//     },
//     formerPlayer: {
//       A: 2,
//       B: 1,
//       C: 4,
//       D: 6,
//       E: 5,
//       F: 3,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 6,
//       D: 4,
//       E: 3,
//       F: 5,
//     },
//     afterTackle: {
//       A: 2,
//       B: 4,
//       C: 1,
//       D: 5,
//       E: 3,
//       F: 6,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 5,
//       D: 3,
//       E: 4,
//       F: 6,
//     },
//     corner: {
//       A: 1,
//       B: 4,
//       C: 5,
//       D: 6,
//       E: 3,
//       F: 2,
//     },
//   },
//   'Stina Blackstenius': {
//     goals: {
//       A: 1,
//       B: 2,
//       C: 3,
//       D: 4,
//       E: 6,
//       F: 5,
//     },
//     strength: {
//       A: 1,
//       B: 6,
//       C: 4,
//       D: 5,
//       E: 2,
//       F: 3,
//     },
//     formerPlayer: {
//       A: 2,
//       B: 3,
//       C: 6,
//       D: 5,
//       E: 4,
//       F: 1,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 5,
//       E: 3,
//       F: 6,
//     },
//     afterTackle: {
//       A: 2,
//       B: 5,
//       C: 1,
//       D: 6,
//       E: 3,
//       F: 4,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 6,
//       E: 3,
//       F: 5,
//     },
//     corner: {
//       A: 5,
//       B: 6,
//       C: 4,
//       D: 2,
//       E: 1,
//       F: 3,
//     },
//   },
//   'Julia Zigiotti Olme': {
//     goals: {
//       A: 1,
//       B: 3,
//       C: 4,
//       D: 6,
//       E: 5,
//       F: 2,
//     },
//     strength: {
//       A: 1,
//       B: 2,
//       C: 5,
//       D: 3,
//       E: 6,
//       F: 4,
//     },
//     formerPlayer: {
//       A: 4,
//       B: 2,
//       C: 6,
//       D: 3,
//       E: 5,
//       F: 1,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 5,
//       E: 3,
//       F: 6,
//     },
//     afterTackle: {
//       A: 2,
//       B: 4,
//       C: 1,
//       D: 6,
//       E: 3,
//       F: 5,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 6,
//       D: 4,
//       E: 3,
//       F: 5,
//     },
//     corner: {
//       A: 6,
//       B: 4,
//       C: 3,
//       D: 5,
//       E: 1,
//       F: 2,
//     },
//   },
//   'Josefine Rybrink': {
//     goals: {
//       A: 6,
//       B: 5,
//       C: 4,
//       D: 3,
//       E: 2,
//       F: 1,
//     },
//     strength: {
//       A: 1,
//       B: 3,
//       C: 2,
//       D: 4,
//       E: 6,
//       F: 5,
//     },
//     formerPlayer: {
//       A: 2,
//       B: 3,
//       C: 6,
//       D: 5,
//       E: 4,
//       F: 1,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 6,
//       C: 5,
//       D: 4,
//       E: 2,
//       F: 3,
//     },
//     afterTackle: {
//       A: 3,
//       B: 4,
//       C: 2,
//       D: 6,
//       E: 1,
//       F: 5,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 3,
//       D: 4,
//       E: 5,
//       F: 6,
//     },
//     corner: {
//       A: 2,
//       B: 3,
//       C: 4,
//       D: 1,
//       E: 6,
//       F: 5,
//     },
//   },
//   'Stephanie Labbé': {
//     goals: {
//       A: 6,
//       B: 5,
//       C: 4,
//       D: 3,
//       E: 2,
//       F: 1,
//     },
//     strength: {
//       A: 6,
//       B: 3,
//       C: 1,
//       D: 4,
//       E: 5,
//       F: 2,
//     },
//     formerPlayer: {
//       A: 1,
//       B: 3,
//       C: 6,
//       D: 4,
//       E: 2,
//       F: 5,
//     },
//     halfwayLine: {
//       A: 5,
//       B: 6,
//       C: 1,
//       D: 4,
//       E: 3,
//       F: 2,
//     },
//     afterTackle: {
//       A: 1,
//       B: 5,
//       C: 6,
//       D: 4,
//       E: 3,
//       F: 2,
//     },
//     breakaway: {
//       A: 6,
//       B: 1,
//       C: 3,
//       D: 4,
//       E: 2,
//       F: 5,
//     },
//     corner: {
//       A: 1,
//       B: 6,
//       C: 3,
//       D: 2,
//       E: 5,
//       F: 4,
//     },
//   },
//   'Mimmi Larsson': {
//     goals: {
//       A: 1,
//       B: 2,
//       C: 3,
//       D: 4,
//       E: 5,
//       F: 6,
//     },
//     strength: {
//       A: 1,
//       B: 6,
//       C: 5,
//       D: 4,
//       E: 2,
//       F: 3,
//     },
//     formerPlayer: {
//       A: 3,
//       B: 1,
//       C: 6,
//       D: 2,
//       E: 5,
//       F: 4,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 3,
//       D: 5,
//       E: 4,
//       F: 6,
//     },
//     afterTackle: {
//       A: 2,
//       B: 4,
//       C: 1,
//       D: 5,
//       E: 3,
//       F: 6,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 6,
//       E: 3,
//       F: 5,
//     },
//     corner: {
//       A: 4,
//       B: 6,
//       C: 5,
//       D: 1,
//       E: 2,
//       F: 3,
//     },
//   },
//   'Jenna Hellstrom': {
//     goals: {
//       A: 1,
//       B: 4,
//       C: 6,
//       D: 5,
//       E: 3,
//       F: 2,
//     },
//     strength: {
//       A: 2,
//       B: 6,
//       C: 4,
//       D: 5,
//       E: 3,
//       F: 1,
//     },
//     formerPlayer: {
//       A: 5,
//       B: 2,
//       C: 6,
//       D: 1,
//       E: 3,
//       F: 4,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 6,
//       D: 5,
//       E: 4,
//       F: 3,
//     },
//     afterTackle: {
//       A: 1,
//       B: 5,
//       C: 4,
//       D: 6,
//       E: 2,
//       F: 3,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 6,
//       D: 5,
//       E: 3,
//       F: 4,
//     },
//     corner: {
//       A: 3,
//       B: 4,
//       C: 2,
//       D: 6,
//       E: 1,
//       F: 5,
//     },
//   },
//   'Loreta Kullashi': {
//     goals: {
//       A: 1,
//       B: 2,
//       C: 3,
//       D: 4,
//       E: 6,
//       F: 5,
//     },
//     strength: {
//       A: 1,
//       B: 4,
//       C: 5,
//       D: 3,
//       E: 6,
//       F: 2,
//     },
//     formerPlayer: {
//       A: 2,
//       B: 5,
//       C: 6,
//       D: 4,
//       E: 3,
//       F: 1,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 6,
//       E: 3,
//       F: 5,
//     },
//     afterTackle: {
//       A: 2,
//       B: 4,
//       C: 1,
//       D: 6,
//       E: 5,
//       F: 3,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 5,
//       D: 6,
//       E: 3,
//       F: 4,
//     },
//     corner: {
//       A: 5,
//       B: 4,
//       C: 6,
//       D: 2,
//       E: 1,
//       F: 3,
//     },
//   },
//   'Madelen Janogy': {
//     goals: {
//       A: 1,
//       B: 2,
//       C: 3,
//       D: 6,
//       E: 5,
//       F: 4,
//     },
//     strength: {
//       A: 1,
//       B: 4,
//       C: 5,
//       D: 2,
//       E: 6,
//       F: 3,
//     },
//     formerPlayer: {
//       A: 3,
//       B: 5,
//       C: 6,
//       D: 4,
//       E: 2,
//       F: 1,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 6,
//       D: 4,
//       E: 3,
//       F: 5,
//     },
//     afterTackle: {
//       A: 2,
//       B: 4,
//       C: 1,
//       D: 6,
//       E: 3,
//       F: 5,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 6,
//       E: 3,
//       F: 5,
//     },
//     corner: {
//       A: 6,
//       B: 3,
//       C: 2,
//       D: 5,
//       E: 1,
//       F: 4,
//     },
//   },
//   'Rosa Kafaji': {
//     goals: {
//       A: 1,
//       B: 2,
//       C: 3,
//       D: 4,
//       E: 6,
//       F: 5,
//     },
//     strength: {
//       A: 1,
//       B: 4,
//       C: 5,
//       D: 3,
//       E: 6,
//       F: 2,
//     },
//     formerPlayer: {
//       A: 2,
//       B: 6,
//       C: 5,
//       D: 4,
//       E: 3,
//       F: 1,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 3,
//       D: 6,
//       E: 5,
//       F: 4,
//     },
//     afterTackle: {
//       A: 2,
//       B: 5,
//       C: 1,
//       D: 6,
//       E: 3,
//       F: 4,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 6,
//       E: 3,
//       F: 5,
//     },
//     corner: {
//       A: 5,
//       B: 2,
//       C: 6,
//       D: 1,
//       E: 3,
//       F: 4,
//     },
//   },
//   'Nellie Lilja': {
//     goals: {
//       A: 4,
//       B: 5,
//       C: 6,
//       D: 3,
//       E: 2,
//       F: 1,
//     },
//     strength: {
//       A: 4,
//       B: 1,
//       C: 3,
//       D: 2,
//       E: 6,
//       F: 5,
//     },
//     formerPlayer: {
//       A: 1,
//       B: 2,
//       C: 6,
//       D: 3,
//       E: 5,
//       F: 4,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 3,
//       C: 4,
//       D: 5,
//       E: 2,
//       F: 6,
//     },
//     afterTackle: {
//       A: 1,
//       B: 4,
//       C: 3,
//       D: 6,
//       E: 2,
//       F: 5,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 6,
//       D: 5,
//       E: 4,
//       F: 3,
//     },
//     corner: {
//       A: 5,
//       B: 3,
//       C: 6,
//       D: 1,
//       E: 2,
//       F: 4,
//     },
//   },
//   'Elin Landström': {
//     goals: {
//       A: 5,
//       B: 6,
//       C: 4,
//       D: 3,
//       E: 2,
//       F: 1,
//     },
//     strength: {
//       A: 1,
//       B: 6,
//       C: 2,
//       D: 4,
//       E: 3,
//       F: 5,
//     },
//     formerPlayer: {
//       A: 2,
//       B: 1,
//       C: 5,
//       D: 3,
//       E: 6,
//       F: 4,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 6,
//       D: 3,
//       E: 4,
//       F: 5,
//     },
//     afterTackle: {
//       A: 3,
//       B: 4,
//       C: 1,
//       D: 5,
//       E: 2,
//       F: 6,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 6,
//       D: 3,
//       E: 4,
//       F: 5,
//     },
//     corner: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 6,
//       E: 3,
//       F: 5,
//     },
//   },
//   'Honoka Hayashi': {
//     goals: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 6,
//       E: 5,
//       F: 3,
//     },
//     strength: {
//       A: 4,
//       B: 1,
//       C: 5,
//       D: 2,
//       E: 6,
//       F: 3,
//     },
//     formerPlayer: {
//       A: 2,
//       B: 1,
//       C: 6,
//       D: 5,
//       E: 4,
//       F: 3,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 4,
//       C: 5,
//       D: 3,
//       E: 2,
//       F: 1,
//     },
//     afterTackle: {
//       A: 1,
//       B: 5,
//       C: 4,
//       D: 3,
//       E: 2,
//       F: 6,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 5,
//       E: 3,
//       F: 6,
//     },
//     corner: {
//       A: 2,
//       B: 4,
//       C: 3,
//       D: 6,
//       E: 1,
//       F: 5,
//     },
//   },
//   'Caroline Seger': {
//     goals: {
//       A: 1,
//       B: 5,
//       C: 6,
//       D: 4,
//       E: 3,
//       F: 2,
//     },
//     strength: {
//       A: 4,
//       B: 1,
//       C: 2,
//       D: 3,
//       E: 5,
//       F: 6,
//     },
//     formerPlayer: {
//       A: 2,
//       B: 3,
//       C: 6,
//       D: 4,
//       E: 5,
//       F: 1,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 3,
//       D: 4,
//       E: 5,
//       F: 6,
//     },
//     afterTackle: {
//       A: 2,
//       B: 5,
//       C: 3,
//       D: 4,
//       E: 1,
//       F: 6,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 6,
//       E: 3,
//       F: 5,
//     },
//     corner: {
//       A: 6,
//       B: 2,
//       C: 4,
//       D: 3,
//       E: 1,
//       F: 5,
//     },
//   },
//   'Olivia Scough': {
//     goals: {
//       A: 1,
//       B: 3,
//       C: 4,
//       D: 5,
//       E: 6,
//       F: 2,
//     },
//     strength: {
//       A: 1,
//       B: 4,
//       C: 5,
//       D: 2,
//       E: 6,
//       F: 3,
//     },
//     formerPlayer: {
//       A: 2,
//       B: 1,
//       C: 6,
//       D: 5,
//       E: 4,
//       F: 3,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 5,
//       D: 4,
//       E: 6,
//       F: 3,
//     },
//     afterTackle: {
//       A: 1,
//       B: 6,
//       C: 2,
//       D: 4,
//       E: 3,
//       F: 5,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 6,
//       E: 3,
//       F: 5,
//     },
//     corner: {
//       A: 3,
//       B: 2,
//       C: 4,
//       D: 6,
//       E: 1,
//       F: 5,
//     },
//   },
//   'Ronja Aronsson': {
//     goals: {
//       A: 5,
//       B: 6,
//       C: 4,
//       D: 3,
//       E: 2,
//       F: 1,
//     },
//     strength: {
//       A: 1,
//       B: 5,
//       C: 3,
//       D: 2,
//       E: 6,
//       F: 4,
//     },
//     formerPlayer: {
//       A: 2,
//       B: 4,
//       C: 6,
//       D: 5,
//       E: 3,
//       F: 1,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 6,
//       D: 5,
//       E: 3,
//       F: 4,
//     },
//     afterTackle: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 6,
//       E: 3,
//       F: 5,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 5,
//       D: 4,
//       E: 3,
//       F: 6,
//     },
//     corner: {
//       A: 1,
//       B: 3,
//       C: 2,
//       D: 4,
//       E: 6,
//       F: 5,
//     },
//   },
//   'Nilla Fischer': {
//     goals: {
//       A: 5,
//       B: 6,
//       C: 4,
//       D: 3,
//       E: 2,
//       F: 1,
//     },
//     strength: {
//       A: 3,
//       B: 2,
//       C: 1,
//       D: 5,
//       E: 6,
//       F: 4,
//     },
//     formerPlayer: {
//       A: 2,
//       B: 1,
//       C: 4,
//       D: 6,
//       E: 3,
//       F: 5,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 3,
//       D: 4,
//       E: 5,
//       F: 6,
//     },
//     afterTackle: {
//       A: 1,
//       B: 4,
//       C: 3,
//       D: 6,
//       E: 2,
//       F: 5,
//     },
//     breakaway: {
//       A: 2,
//       B: 1,
//       C: 5,
//       D: 4,
//       E: 3,
//       F: 6,
//     },
//     corner: {
//       A: 6,
//       B: 5,
//       C: 3,
//       D: 2,
//       E: 1,
//       F: 4,
//     },
//   },
//   'Elin Karlsson': {
//     goals: {
//       A: 6,
//       B: 5,
//       C: 4,
//       D: 3,
//       E: 2,
//       F: 1,
//     },
//     strength: {
//       A: 5,
//       B: 6,
//       C: 1,
//       D: 3,
//       E: 2,
//       F: 4,
//     },
//     formerPlayer: {
//       A: 5,
//       B: 1,
//       C: 2,
//       D: 6,
//       E: 4,
//       F: 3,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 6,
//       C: 4,
//       D: 2,
//       E: 3,
//       F: 5,
//     },
//     afterTackle: {
//       A: 1,
//       B: 4,
//       C: 2,
//       D: 6,
//       E: 3,
//       F: 5,
//     },
//     breakaway: {
//       A: 1,
//       B: 6,
//       C: 4,
//       D: 2,
//       E: 5,
//       F: 3,
//     },
//     corner: {
//       A: 6,
//       B: 4,
//       C: 5,
//       D: 1,
//       E: 3,
//       F: 2,
//     },
//   },
//   'Lisa Klinga': {
//     goals: {
//       A: 6,
//       B: 5,
//       C: 4,
//       D: 3,
//       E: 2,
//       F: 1,
//     },
//     strength: {
//       A: 3,
//       B: 6,
//       C: 1,
//       D: 4,
//       E: 2,
//       F: 5,
//     },
//     formerPlayer: {
//       A: 5,
//       B: 1,
//       C: 3,
//       D: 4,
//       E: 6,
//       F: 2,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 6,
//       D: 4,
//       E: 3,
//       F: 5,
//     },
//     afterTackle: {
//       A: 1,
//       B: 4,
//       C: 3,
//       D: 6,
//       E: 2,
//       F: 5,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 6,
//       D: 3,
//       E: 4,
//       F: 5,
//     },
//     corner: {
//       A: 5,
//       B: 1,
//       C: 4,
//       D: 2,
//       E: 6,
//       F: 3,
//     },
//   },
//   'Fernanda Da Silva': {
//     goals: {
//       A: 1,
//       B: 5,
//       C: 6,
//       D: 4,
//       E: 3,
//       F: 2,
//     },
//     strength: {
//       A: 1,
//       B: 3,
//       C: 5,
//       D: 4,
//       E: 6,
//       F: 2,
//     },
//     formerPlayer: {
//       A: 6,
//       B: 4,
//       C: 5,
//       D: 1,
//       E: 3,
//       F: 2,
//     },
//     halfwayLine: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 3,
//       E: 6,
//       F: 5,
//     },
//     afterTackle: {
//       A: 2,
//       B: 6,
//       C: 1,
//       D: 4,
//       E: 3,
//       F: 5,
//     },
//     breakaway: {
//       A: 1,
//       B: 2,
//       C: 4,
//       D: 6,
//       E: 3,
//       F: 5,
//     },
//     corner: {
//       A: 3,
//       B: 2,
//       C: 4,
//       D: 6,
//       E: 1,
//       F: 5,
//     },
//   },
// }
export const answersByPlayer = {
    'Jennifer Falk': {
        goals: {
            A: 6,
            B: 5,
            C: 4,
            D: 3,
            E: 2,
            F: 1,
        },
        strength: {
            A: 6,
            B: 5,
            C: 1,
            D: 3,
            E: 4,
            F: 2,
        },
        formerPlayer: {
            A: 2,
            B: 1,
            C: 5,
            D: 6,
            E: 3,
            F: 4,
        },
        halfwayLine: {
            A: 5,
            B: 6,
            C: 1,
            D: 3,
            E: 2,
            F: 4,
        },
        afterTackle: {
            A: 1,
            B: 5,
            C: 2,
            D: 4,
            E: 6,
            F: 3,
        },
        breakaway: {
            A: 6,
            B: 1,
            C: 3,
            D: 2,
            E: 4,
            F: 5,
        },
        corner: {
            A: 5,
            B: 3,
            C: 2,
            D: 1,
            E: 6,
            F: 4,
        },
    },
    'Elin Rubensson': {
        goals: {
            A: 2,
            B: 4,
            C: 6,
            D: 5,
            E: 3,
            F: 1,
        },
        strength: {
            A: 1,
            B: 5,
            C: 3,
            D: 2,
            E: 6,
            F: 4,
        },
        formerPlayer: {
            A: 2,
            B: 1,
            C: 4,
            D: 6,
            E: 5,
            F: 3,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 6,
            D: 4,
            E: 3,
            F: 5,
        },
        afterTackle: {
            A: 2,
            B: 4,
            C: 1,
            D: 5,
            E: 3,
            F: 6,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 5,
            D: 3,
            E: 4,
            F: 6,
        },
        corner: {
            A: 1,
            B: 4,
            C: 5,
            D: 6,
            E: 3,
            F: 2,
        },
    },
    'Josefine Rybrink': {
        goals: {
            A: 6,
            B: 5,
            C: 4,
            D: 3,
            E: 2,
            F: 1,
        },
        strength: {
            A: 1,
            B: 3,
            C: 2,
            D: 4,
            E: 6,
            F: 5,
        },
        formerPlayer: {
            A: 2,
            B: 3,
            C: 6,
            D: 5,
            E: 4,
            F: 1,
        },
        halfwayLine: {
            A: 1,
            B: 6,
            C: 5,
            D: 4,
            E: 2,
            F: 3,
        },
        afterTackle: {
            A: 3,
            B: 4,
            C: 2,
            D: 6,
            E: 1,
            F: 5,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 3,
            D: 4,
            E: 5,
            F: 6,
        },
        corner: {
            A: 2,
            B: 3,
            C: 4,
            D: 1,
            E: 6,
            F: 5,
        },
    },
    'Jenna Hellstrom': {
        goals: {
            A: 1,
            B: 4,
            C: 6,
            D: 5,
            E: 3,
            F: 2,
        },
        strength: {
            A: 2,
            B: 6,
            C: 4,
            D: 5,
            E: 3,
            F: 1,
        },
        formerPlayer: {
            A: 5,
            B: 2,
            C: 6,
            D: 1,
            E: 3,
            F: 4,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 6,
            D: 5,
            E: 4,
            F: 3,
        },
        afterTackle: {
            A: 1,
            B: 5,
            C: 4,
            D: 6,
            E: 2,
            F: 3,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 6,
            D: 5,
            E: 3,
            F: 4,
        },
        corner: {
            A: 3,
            B: 4,
            C: 2,
            D: 6,
            E: 1,
            F: 5,
        },
    },
    'Loreta Kullashi': {
        goals: {
            A: 1,
            B: 2,
            C: 3,
            D: 4,
            E: 6,
            F: 5,
        },
        strength: {
            A: 1,
            B: 4,
            C: 5,
            D: 3,
            E: 6,
            F: 2,
        },
        formerPlayer: {
            A: 2,
            B: 5,
            C: 6,
            D: 4,
            E: 3,
            F: 1,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 4,
            D: 6,
            E: 3,
            F: 5,
        },
        afterTackle: {
            A: 2,
            B: 4,
            C: 1,
            D: 6,
            E: 5,
            F: 3,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 5,
            D: 6,
            E: 3,
            F: 4,
        },
        corner: {
            A: 5,
            B: 4,
            C: 6,
            D: 2,
            E: 1,
            F: 3,
        },
    },
    'Madelen Janogy': {
        goals: {
            A: 1,
            B: 2,
            C: 3,
            D: 6,
            E: 5,
            F: 4,
        },
        strength: {
            A: 1,
            B: 4,
            C: 5,
            D: 2,
            E: 6,
            F: 3,
        },
        formerPlayer: {
            A: 3,
            B: 5,
            C: 6,
            D: 4,
            E: 2,
            F: 1,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 6,
            D: 4,
            E: 3,
            F: 5,
        },
        afterTackle: {
            A: 2,
            B: 4,
            C: 1,
            D: 6,
            E: 3,
            F: 5,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 4,
            D: 6,
            E: 3,
            F: 5,
        },
        corner: {
            A: 6,
            B: 3,
            C: 2,
            D: 5,
            E: 1,
            F: 4,
        },
    },
    'Mimmi Larsson': {
        goals: {
            A: 1,
            B: 2,
            C: 3,
            D: 4,
            E: 5,
            F: 6,
        },
        strength: {
            A: 1,
            B: 6,
            C: 5,
            D: 4,
            E: 2,
            F: 3,
        },
        formerPlayer: {
            A: 3,
            B: 1,
            C: 6,
            D: 2,
            E: 5,
            F: 4,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 3,
            D: 5,
            E: 4,
            F: 6,
        },
        afterTackle: {
            A: 2,
            B: 4,
            C: 1,
            D: 5,
            E: 3,
            F: 6,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 4,
            D: 6,
            E: 3,
            F: 5,
        },
        corner: {
            A: 4,
            B: 6,
            C: 5,
            D: 1,
            E: 2,
            F: 3,
        },
    },
    'Nellie Lilja': {
        goals: {
            A: 4,
            B: 5,
            C: 6,
            D: 3,
            E: 2,
            F: 1,
        },
        strength: {
            A: 4,
            B: 1,
            C: 3,
            D: 2,
            E: 6,
            F: 5,
        },
        formerPlayer: {
            A: 1,
            B: 2,
            C: 6,
            D: 3,
            E: 5,
            F: 4,
        },
        halfwayLine: {
            A: 1,
            B: 3,
            C: 4,
            D: 5,
            E: 2,
            F: 6,
        },
        afterTackle: {
            A: 1,
            B: 4,
            C: 3,
            D: 6,
            E: 2,
            F: 5,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 6,
            D: 5,
            E: 4,
            F: 3,
        },
        corner: {
            A: 5,
            B: 3,
            C: 6,
            D: 1,
            E: 2,
            F: 4,
        },
    },
    'Honoka Hayashi': {
        goals: {
            A: 1,
            B: 2,
            C: 4,
            D: 6,
            E: 5,
            F: 3,
        },
        strength: {
            A: 4,
            B: 1,
            C: 5,
            D: 2,
            E: 6,
            F: 3,
        },
        formerPlayer: {
            A: 2,
            B: 1,
            C: 6,
            D: 5,
            E: 4,
            F: 3,
        },
        halfwayLine: {
            A: 1,
            B: 4,
            C: 5,
            D: 3,
            E: 2,
            F: 1,
        },
        afterTackle: {
            A: 1,
            B: 5,
            C: 4,
            D: 3,
            E: 2,
            F: 6,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 4,
            D: 5,
            E: 3,
            F: 6,
        },
        corner: {
            A: 2,
            B: 4,
            C: 3,
            D: 6,
            E: 1,
            F: 5,
        },
    },
    'Caroline Seger': {
        goals: {
            A: 1,
            B: 5,
            C: 6,
            D: 4,
            E: 3,
            F: 2,
        },
        strength: {
            A: 4,
            B: 1,
            C: 2,
            D: 3,
            E: 5,
            F: 6,
        },
        formerPlayer: {
            A: 2,
            B: 3,
            C: 6,
            D: 4,
            E: 5,
            F: 1,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 3,
            D: 4,
            E: 5,
            F: 6,
        },
        afterTackle: {
            A: 2,
            B: 5,
            C: 3,
            D: 4,
            E: 1,
            F: 6,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 4,
            D: 6,
            E: 3,
            F: 5,
        },
        corner: {
            A: 6,
            B: 2,
            C: 4,
            D: 3,
            E: 1,
            F: 5,
        },
    },
    'Olivia Scough': {
        goals: {
            A: 1,
            B: 3,
            C: 4,
            D: 5,
            E: 6,
            F: 2,
        },
        strength: {
            A: 1,
            B: 4,
            C: 5,
            D: 2,
            E: 6,
            F: 3,
        },
        formerPlayer: {
            A: 2,
            B: 1,
            C: 6,
            D: 5,
            E: 4,
            F: 3,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 5,
            D: 4,
            E: 6,
            F: 3,
        },
        afterTackle: {
            A: 1,
            B: 6,
            C: 2,
            D: 4,
            E: 3,
            F: 5,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 4,
            D: 6,
            E: 3,
            F: 5,
        },
        corner: {
            A: 3,
            B: 2,
            C: 4,
            D: 6,
            E: 1,
            F: 5,
        },
    },
    'Nilla Fischer': {
        goals: {
            A: 5,
            B: 6,
            C: 4,
            D: 3,
            E: 2,
            F: 1,
        },
        strength: {
            A: 3,
            B: 2,
            C: 1,
            D: 5,
            E: 6,
            F: 4,
        },
        formerPlayer: {
            A: 2,
            B: 1,
            C: 4,
            D: 6,
            E: 3,
            F: 5,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 3,
            D: 4,
            E: 5,
            F: 6,
        },
        afterTackle: {
            A: 1,
            B: 4,
            C: 3,
            D: 6,
            E: 2,
            F: 5,
        },
        breakaway: {
            A: 2,
            B: 1,
            C: 5,
            D: 4,
            E: 3,
            F: 6,
        },
        corner: {
            A: 6,
            B: 5,
            C: 3,
            D: 2,
            E: 1,
            F: 4,
        },
    },
    'Lisa Klinga': {
        goals: {
            A: 6,
            B: 5,
            C: 4,
            D: 3,
            E: 2,
            F: 1,
        },
        strength: {
            A: 3,
            B: 6,
            C: 1,
            D: 4,
            E: 2,
            F: 5,
        },
        formerPlayer: {
            A: 5,
            B: 1,
            C: 3,
            D: 4,
            E: 6,
            F: 2,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 6,
            D: 4,
            E: 3,
            F: 5,
        },
        afterTackle: {
            A: 1,
            B: 4,
            C: 3,
            D: 6,
            E: 2,
            F: 5,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 6,
            D: 3,
            E: 4,
            F: 5,
        },
        corner: {
            A: 5,
            B: 1,
            C: 4,
            D: 2,
            E: 6,
            F: 3,
        },
    },
    'Mia Jalkerud': {
        goals: {
            A: 1,
            B: 2,
            C: 5,
            D: 6,
            E: 4,
            F: 3,
        },
        strength: {
            A: 1,
            B: 3,
            C: 6,
            D: 5,
            E: 2,
            F: 4,
        },
        formerPlayer: {
            A: 3,
            B: 2,
            C: 6,
            D: 4,
            E: 4,
            F: 1,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 4,
            D: 3,
            E: 5,
            F: 6,
        },
        afterTackle: {
            A: 3,
            B: 4,
            C: 1,
            D: 6,
            E: 2,
            F: 5,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 4,
            D: 5,
            E: 3,
            F: 6,
        },
        corner: {
            A: 2,
            B: 5,
            C: 6,
            D: 4,
            E: 1,
            F: 3,
        },
    },
    'Matilda Plan': {
        goals: {
            A: 6,
            B: 5,
            C: 4,
            D: 3,
            E: 2,
            F: 1,
        },
        strength: {
            A: 4,
            B: 3,
            C: 1,
            D: 6,
            E: 2,
            F: 5,
        },
        formerPlayer: {
            A: 3,
            B: 1,
            C: 6,
            D: 5,
            E: 2,
            F: 4,
        },
        halfwayLine: {
            A: 1,
            B: 3,
            C: 4,
            D: 5,
            E: 2,
            F: 6,
        },
        afterTackle: {
            A: 1,
            B: 4,
            C: 2,
            D: 6,
            E: 3,
            F: 5,
        },
        breakaway: {
            A: 2,
            B: 1,
            C: 6,
            D: 3,
            E: 5,
            F: 4,
        },
        corner: {
            A: 6,
            B: 3,
            C: 5,
            D: 1,
            E: 2,
            F: 4,
        },
    },
    'Tabby Tindell': {
        goals: {
            A: 1,
            B: 2,
            C: 3,
            D: 4,
            E: 6,
            F: 5,
        },
        strength: {
            A: 6,
            B: 5,
            C: 1,
            D: 3,
            E: 4,
            F: 2,
        },
        formerPlayer: {
            A: 2,
            B: 6,
            C: 5,
            D: 3,
            E: 4,
            F: 1,
        },
        halfwayLine: {
            A: 6,
            B: 1,
            C: 4,
            D: 3,
            E: 2,
            F: 5,
        },
        afterTackle: {
            A: 1,
            B: 6,
            C: 2,
            D: 5,
            E: 3,
            F: 4,
        },
        breakaway: {
            A: 2,
            B: 1,
            C: 6,
            D: 4,
            E: 3,
            F: 5,
        },
        corner: {
            A: 5,
            B: 4,
            C: 6,
            D: 2,
            E: 1,
            F: 3,
        },
    },
    'Évelyne Viens': {
        goals: {
            A: 1,
            B: 2,
            C: 3,
            D: 4,
            E: 6,
            F: 5,
        },
        strength: {
            A: 2,
            B: 4,
            C: 5,
            D: 6,
            E: 3,
            F: 1,
        },
        formerPlayer: {
            A: 2,
            B: 6,
            C: 3,
            D: 5,
            E: 4,
            F: 1,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 4,
            D: 5,
            E: 3,
            F: 6,
        },
        afterTackle: {
            A: 1,
            B: 4,
            C: 6,
            D: 5,
            E: 2,
            F: 3,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 6,
            D: 5,
            E: 3,
            F: 4,
        },
        corner: {
            A: 4,
            B: 6,
            C: 5,
            D: 2,
            E: 1,
            F: 3,
        },
    },
    'Julia Walentowicz': {
        goals: {
            A: 3,
            B: 6,
            C: 5,
            D: 2,
            E: 1,
            F: 4,
        },
        strength: {
            A: 3,
            B: 6,
            C: 2,
            D: 1,
            E: 4,
            F: 5,
        },
        formerPlayer: {
            A: 1,
            B: 4,
            C: 6,
            D: 5,
            E: 3,
            F: 2,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 6,
            D: 4,
            E: 3,
            F: 5,
        },
        afterTackle: {
            A: 2,
            B: 3,
            C: 4,
            D: 6,
            E: 1,
            F: 5,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 4,
            D: 6,
            E: 3,
            F: 5,
        },
        corner: {
            A: 2,
            B: 4,
            C: 3,
            D: 1,
            E: 6,
            F: 5,
        },
    },
    'Elli Pikkujämsä': {
        goals: {
            A: 4,
            B: 5,
            C: 6,
            D: 3,
            E: 2,
            F: 1,
        },
        strength: {
            A: 1,
            B: 4,
            C: 2,
            D: 6,
            E: 5,
            F: 3,
        },
        formerPlayer: {
            A: 1,
            B: 4,
            C: 5,
            D: 6,
            E: 3,
            F: 2,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 3,
            D: 4,
            E: 6,
            F: 5,
        },
        afterTackle: {
            A: 3,
            B: 5,
            C: 2,
            D: 6,
            E: 1,
            F: 4,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 6,
            D: 4,
            E: 3,
            F: 5,
        },
        corner: {
            A: 5,
            B: 3,
            C: 2,
            D: 4,
            E: 1,
            F: 6,
        },
    },
    'Agnes Nyberg': {
        goals: {
            A: 5,
            B: 6,
            C: 4,
            D: 3,
            E: 2,
            F: 1,
        },
        strength: {
            A: 1,
            B: 3,
            C: 2,
            D: 4,
            E: 5,
            F: 6,
        },
        formerPlayer: {
            A: 1,
            B: 3,
            C: 4,
            D: 5,
            E: 6,
            F: 2,
        },
        halfwayLine: {
            A: 1,
            B: 4,
            C: 3,
            D: 2,
            E: 5,
            F: 6,
        },
        afterTackle: {
            A: 1,
            B: 5,
            C: 2,
            D: 6,
            E: 4,
            F: 3,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 6,
            D: 3,
            E: 4,
            F: 5,
        },
        corner: {
            A: 5,
            B: 3,
            C: 6,
            D: 1,
            E: 2,
            F: 4,
        },
    },
    'Katrina Guillou': {
        goals: {
            A: 1,
            B: 2,
            C: 3,
            D: 6,
            E: 5,
            F: 4,
        },
        strength: {
            A: 5,
            B: 6,
            C: 4,
            D: 1,
            E: 3,
            F: 2,
        },
        formerPlayer: {
            A: 0,
            B: 0,
            C: 0,
            D: 0,
            E: 0,
            F: 0,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 4,
            D: 3,
            E: 5,
            F: 6,
        },
        afterTackle: {
            A: 2,
            B: 5,
            C: 1,
            D: 4,
            E: 3,
            F: 6,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 6,
            D: 5,
            E: 3,
            F: 4,
        },
        corner: {
            A: 2,
            B: 4,
            C: 3,
            D: 5,
            E: 1,
            F: 6,
        },
    },
    'Filippa Curmark': {
        goals: {
            A: 4,
            B: 5,
            C: 6,
            D: 3,
            E: 2,
            F: 1,
        },
        strength: {
            A: 1,
            B: 2,
            C: 3,
            D: 4,
            E: 5,
            F: 6,
        },
        formerPlayer: {
            A: 2,
            B: 1,
            C: 4,
            D: 5,
            E: 6,
            F: 3,
        },
        halfwayLine: {
            A: 1,
            B: 6,
            C: 4,
            D: 3,
            E: 2,
            F: 5,
        },
        afterTackle: {
            A: 3,
            B: 1,
            C: 2,
            D: 6,
            E: 4,
            F: 5,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 5,
            D: 3,
            E: 4,
            F: 6,
        },
        corner: {
            A: 4,
            B: 1,
            C: 5,
            D: 3,
            E: 2,
            F: 6,
        },
    },
    'Alice Carlsson': {
        goals: {
            A: 5,
            B: 6,
            C: 4,
            D: 3,
            E: 2,
            F: 1,
        },
        strength: {
            A: 3,
            B: 2,
            C: 1,
            D: 5,
            E: 6,
            F: 4,
        },
        formerPlayer: {
            A: 3,
            B: 5,
            C: 4,
            D: 2,
            E: 6,
            F: 1,
        },
        halfwayLine: {
            A: 5,
            B: 6,
            C: 2,
            D: 4,
            E: 1,
            F: 3,
        },
        afterTackle: {
            A: 2,
            B: 4,
            C: 5,
            D: 6,
            E: 1,
            F: 3,
        },
        breakaway: {
            A: 1,
            B: 3,
            C: 6,
            D: 2,
            E: 4,
            F: 5,
        },
        corner: {
            A: 6,
            B: 4,
            C: 5,
            D: 1,
            E: 2,
            F: 3,
        },
    },
    'Paige Culver': {
        goals: {
            A: 5,
            B: 6,
            C: 4,
            D: 3,
            E: 2,
            F: 1,
        },
        strength: {
            A: 4,
            B: 3,
            C: 1,
            D: 6,
            E: 2,
            F: 5,
        },
        formerPlayer: {
            A: 3,
            B: 5,
            C: 6,
            D: 2,
            E: 1,
            F: 4,
        },
        halfwayLine: {
            A: 1,
            B: 4,
            C: 2,
            D: 6,
            E: 3,
            F: 5,
        },
        afterTackle: {
            A: 1,
            B: 5,
            C: 3,
            D: 6,
            E: 2,
            F: 4,
        },
        breakaway: {
            A: 1,
            B: 5,
            C: 4,
            D: 2,
            E: 6,
            F: 3,
        },
        corner: {
            A: 6,
            B: 5,
            C: 2,
            D: 1,
            E: 4,
            F: 3,
        },
    },
    'Lisa Björk': {
        goals: {
            A: 2,
            B: 3,
            C: 5,
            D: 6,
            E: 4,
            F: 1,
        },
        strength: {
            A: 2,
            B: 6,
            C: 5,
            D: 3,
            E: 4,
            F: 1,
        },
        formerPlayer: {
            A: 2,
            B: 4,
            C: 6,
            D: 3,
            E: 5,
            F: 1,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 6,
            D: 3,
            E: 4,
            F: 5,
        },
        afterTackle: {
            A: 3,
            B: 2,
            C: 5,
            D: 6,
            E: 1,
            F: 4,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 6,
            D: 4,
            E: 3,
            F: 5,
        },
        corner: {
            A: 4,
            B: 3,
            C: 5,
            D: 2,
            E: 1,
            F: 6,
        },
    },
    'Amalie Vansgaard': {
        goals: {
            A: 1,
            B: 2,
            C: 3,
            D: 4,
            E: 6,
            F: 5,
        },
        strength: {
            A: 3,
            B: 6,
            C: 5,
            D: 2,
            E: 4,
            F: 1,
        },
        formerPlayer: {
            A: 4,
            B: 5,
            C: 6,
            D: 3,
            E: 2,
            F: 1,
        },
        halfwayLine: {
            A: 1,
            B: 2,
            C: 3,
            D: 4,
            E: 5,
            F: 6,
        },
        afterTackle: {
            A: 2,
            B: 3,
            C: 4,
            D: 5,
            E: 1,
            F: 6,
        },
        breakaway: {
            A: 1,
            B: 2,
            C: 5,
            D: 3,
            E: 3,
            F: 4,
        },
        corner: {
            A: 6,
            B: 5,
            C: 4,
            D: 3,
            E: 1,
            F: 2,
        },
    },
};
