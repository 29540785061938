import { writable } from 'svelte/store';
function createAnswersStore() {
    const { subscribe, set, update } = writable({});
    return {
        subscribe,
        answer: (questionId, alternative) => update((state) => (Object.assign(Object.assign({}, state), { [questionId]: alternative }))),
        reset: () => set({}),
    };
}
export const answers = createAnswersStore();
