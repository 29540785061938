<script lang="ts">
  export let size: 'small' | 'large' | undefined = undefined
  export let type: svelte.JSX.HTMLAttributes<HTMLButtonElement>['type'] =
    'button'

  let clazz = ''

  function joinClasses(classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  $: classNames = joinClasses([
    'uppercase font-medium rounded-lg focus:ring-4 focus:ring-white focus:ring-opacity-30',
    !size && 'text-xl px-5 py-4',
    size === 'large' && 'text-2xl px-7 py-5',
    size === 'small' && 'text-base px-4 py-3',
    clazz,
  ])

  export { clazz as class }
</script>

<button class={classNames} on:click {type}>
  <slot />
</button>

<style>
  button {
    background: rgb(148, 156, 169);
    background: linear-gradient(
      135deg,
      rgba(148, 156, 169, 1) 0%,
      rgba(202, 203, 211, 1) 50%,
      rgba(210, 202, 187, 1) 100%
    );
    color: white;
    text-shadow: 0 1px 1px hsla(0deg, 0%, 0%, 0.6);
  }

  button:focus,
  button:hover {
    opacity: 0.9;
  }

  button:active {
    opacity: 0.8;
  }
</style>
