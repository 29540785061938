<script lang="ts">
  import { onMount, onDestroy } from 'svelte'
  import { fade } from 'svelte/transition'
  import ArrowDown from '../icons/ArrowDown.svelte'

  let visible = false
  let didHide = false
  let timer: NodeJS.Timeout

  onMount(() => {
    const scrollableHeight =
      document.body.offsetHeight - (window.innerHeight + window.scrollY)
    const scrollableRatio = scrollableHeight / document.body.offsetHeight

    if (scrollableRatio > 0.2) {
      timer = setTimeout(() => {
        visible = true
      }, 800)
    }
  })

  onDestroy(() => {
    clearTimeout(timer)
  })

  function maybeHideScrollHint() {
    if (didHide) {
      return
    }

    const scrollableDistance = document.body.offsetHeight - window.innerHeight
    const scrolledDistance = window.scrollY
    const scrolledRatio = scrolledDistance / scrollableDistance

    if (scrolledRatio >= 0.5) {
      visible = false
      didHide = true
    }
  }

  const handleScroll: svelte.JSX.UIEventHandler<Window> = () => {
    maybeHideScrollHint()
  }

  const handleResize: svelte.JSX.UIEventHandler<Window> = () => {
    maybeHideScrollHint()
  }
</script>

<svelte:window on:scroll={handleScroll} on:resize={handleResize} />

{#if visible}
  <div transition:fade>
    <ArrowDown />
  </div>
{/if}

<style>
  div {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    animation: float 4s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-0.5rem);
    }
    100% {
      transform: translateY(0);
    }
  }
</style>
