<script lang="ts">
  import { createEventDispatcher } from 'svelte'

  import type { Player } from '../data/players'
  import Instagram from '../icons/Instagram.svelte'
  import ScrollHint from './ScrollHint.svelte'
  import SpecialButton from './SpecialButton.svelte'

  export let player: Player

  const dispatch = createEventDispatcher()

  function handleReset() {
    dispatch('reset')
  }
</script>

<ScrollHint />

<div class="wrapper grid grid-cols-1 gap-8 lg:grid-cols-2">
  <div class="block lg:hidden space-y-1 text-center">
    <h5 class="text-4xl font-medium uppercase">{player.name}</h5>
    <span class="block text-xl font-medium uppercase">{player.team}</span>
  </div>
  <div class="img flex flex-col items-center">
    <img
      class="max-w-full"
      src={player.img}
      alt={player.name}
      width="416"
      height="505"
    />
  </div>
  <div>
    <div class="hidden lg:block lg:mb-8 space-y-1">
      <h5 class="text-4xl font-medium uppercase">{player.name}</h5>
      <span class="block text-xl font-medium uppercase">{player.team}</span>
    </div>
    <div>
      <p class="leading-relaxed">{player.description}</p>
      <div class="flex flex-col items-center space-y-8 mt-8">
        {#if player.instagram}
          <a
            class="flex flex-col items-center space-y-2"
            href={`https://www.instagram.com/${player.instagram}/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram size="48" />
            <span class="text-sm">
              Följ {player.name}!
            </span>
          </a>
        {/if}
        <SpecialButton on:click={handleReset}>Gör testet igen</SpecialButton>
      </div>
      <div class="mt-20 flex flex-col items-center lg:items-end">
        <img
          src="/logos/partnerlogov2.webp"
          alt="logotyper av våra samarbetspartners"
          class="max-w-full w-[282px]"
        />
      </div>
    </div>
  </div>
</div>
