export const questions = [
    {
        id: 'goals',
        label: 'Hur många mål gör du per säsong?',
        alternatives: {
            A: '0',
            B: '1-2',
            C: '3-5',
            D: '6-9',
            E: '10-14',
            F: '15+',
        },
    },
    {
        id: 'strength',
        label: 'Din styrka',
        alternatives: {
            A: 'Mina reflexer',
            B: 'Jag är snabb ',
            C: 'Att göra avslut ',
            D: 'Min styrka ',
            E: 'Min bollkontroll',
            F: 'Jag är en bollvinnare',
        },
    },
    {
        id: 'formerPlayer',
        label: 'Vem skulle du vilja spela med?',
        alternatives: {
            A: 'Kristin Bengtsson (back)',
            B: 'Pia Sundhage (forward)',
            C: 'Lotta Schelin (forward)',
            D: 'Hanna Marklund (back)',
            E: 'Malin Moström (mittfält) ',
            F: 'Caroline Jönsson (målvakt)',
        },
    },
    {
        id: 'halfwayLine',
        label: 'Du får bollen vid mittlinjen, vad gör du?',
        alternatives: {
            A: 'Springer snabbt bakåt. Jag ska ju stå i mål! ',
            B: 'Sparkar bollen så långt jag kan. ',
            C: 'Tar den mot hörnflaggan och slår ett vasst inlägg.',
            D: 'Jag kör en soloräd tills någon stoppar mig med en ful tackling.',
            E: 'Jag tar bollen och skjuter ett hårt skott i krysset ',
            F: 'Jag passar den vidare mot kanten och tar en löpning mot boxen, får tillbaka bollen och gör mål.',
        },
    },
    {
        id: 'afterTackle',
        label: 'En spelare försöker tackla dig, vad gör du?',
        alternatives: {
            A: 'Jag ramlar och håller för mitt ansikte ',
            B: 'Jag ramlar, men ställer mig så klart upp direkt ',
            C: 'Det där kan jag inte ta. Jag startar ett bråk! ',
            D: 'Jag möter tacklingen med kraft och tacklar tillbaka',
            E: 'Jag faller, rullar några varv och skriker för att få domarens uppmärksamhet. Frispark, tack! ',
            F: 'Jag försöker stå kvar, men tappar balansen och ramlar',
        },
    },
    {
        id: 'breakaway',
        label: 'Du är fri mot målvakten, vad gör du?',
        alternatives: {
            A: 'Jag ÄR målvakten – jag gör en otrolig benparad. ',
            B: 'Får panik och sparkar bollen mot hörnflaggan. ',
            C: 'Skjuter ett hårt, men bra placerat, skott förbi målvakten.',
            D: 'Jag går runt målvakten och rullar in bollen.',
            E: 'Det handlar om kraft. Drar iväg ett hårt skott mitt på målvakten. ',
            F: 'Jag placerar bollen med perfekt skruv förbi målvakten.',
        },
    },
    {
        id: 'corner',
        label: 'Ditt lag får en hörna, var befinner du dig?',
        alternatives: {
            A: 'Utanför straffområdet och med snabba kliv attackerar jag bollen med huvudet. ',
            B: 'Jag punktmarkerar motståndarens målvakt.',
            C: 'Håller låg profil och hoppas att bollen dimper ner vid mina fötter. ',
            D: 'Jag slår hörnan. ',
            E: 'Mig hittar du vid mittlinjen. Hörnor = rast. ',
            F: 'Jag väntar tålmodigt utanför straffområdet alltid redo för en volley.',
        },
    },
];
export const questionsById = questions.reduce((acc, question) => {
    acc[question.id] = question;
    return acc;
}, {});
