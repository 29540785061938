import './tailwind.generated.css';
import App from './App.svelte';
// import App from './Images.svelte'
const app = new App({
    target: document.getElementById('root'),
    props: {
        showCookieInfo: window.location.hash.indexOf('cookie') > -1,
        showJerseyForm: false,
    },
});
export default app;
