<script lang="ts">
  import { tick } from 'svelte'
  import { fly, fade } from 'svelte/transition'
  import {
    getTopPlayerBasedOnAnswers,
    positionAlternatives,
  } from '../data/players'
  import type { Player, Position } from '../data/players'
  import { questionsById } from '../data/questions'
  import type { QuestionId } from '../data/questions'
  import { answers } from '../stores/answers-store'
  import type { AnswersState } from '../stores/answers-store'
  import RadioGroup from './RadioGroup.svelte'
  import SpecialButton from './SpecialButton.svelte'
  import ChevronUp from '../icons/ChevronUp.svelte'
  import ChevronDown from '../icons/ChevronDown.svelte'
  import { Tracking } from '../utils/tracking'

  export let isJerseyCompetitionActive: boolean = false
  export let onResult: (player: Player) => void

  const steps: Array<QuestionId | 'position' | 'intro' | 'outro'> = [
    'intro',
    'position',
    'goals',
    'strength',
    'formerPlayer',
    'halfwayLine',
    'afterTackle',
    'breakaway',
    'corner',
    'outro',
  ]

  let currentStepIndex = 0
  let validationErrors: Partial<Record<keyof AnswersState, true>> = {}
  let lastNavDirection: 'back' | 'forward' = 'forward'
  let isTransitioning = false
  let navTimer: NodeJS.Timeout
  let formNode: HTMLFormElement

  $: currentStep = steps[currentStepIndex]
  $: currentQuestion = questionsById[currentStep]
  $: canGoBackward = currentStepIndex > 0
  $: hasAnsweredCurrentQuestion =
    currentStep === 'intro' || !!$answers[currentStep]
  $: canGoForward =
    currentStepIndex < steps.length - 1 && hasAnsweredCurrentQuestion

  function handleTransitionStart() {
    isTransitioning = true
  }

  function handleTransitionEnd() {
    isTransitioning = false
    tryToFocusFirstInput()
  }

  function flyIn(node: Element, _opts?: Record<string, any>) {
    return fly(node, {
      duration: 600,
      y: lastNavDirection === 'forward' ? 120 : -120,
    })
  }

  function flyOut(node: Element, _opts?: Record<string, any>) {
    return fly(node, {
      duration: 600,
      y: lastNavDirection === 'forward' ? -120 : 120,
    })
  }

  async function tryToFocusFirstInput() {
    await tick()
    try {
      ;(formNode.elements[0] as HTMLInputElement)?.focus({
        preventScroll: true,
      })
    } catch (error) {
      // ignore
    }
  }

  function goForward() {
    lastNavDirection = 'forward'
    currentStepIndex = currentStepIndex + 1
    Tracking.pageviewOnce(`/quiz/${steps[currentStepIndex]}`)
  }

  function handleBackClick() {
    if (canGoBackward) {
      clearTimeout(navTimer)
      lastNavDirection = 'back'
      currentStepIndex = currentStepIndex - 1
    }
  }

  function handleForwardClick() {
    if (canGoForward) {
      clearTimeout(navTimer)
      goForward()
    }
  }

  function handleAnswer(event: {
    detail: { questionId: QuestionId; alternativeKey: string }
  }) {
    const { questionId, alternativeKey } = event.detail
    answers.answer(questionId, alternativeKey)
    Tracking.event('Answers', questionId, { label: alternativeKey })

    navTimer = setTimeout(() => {
      goForward()
    }, 225)
  }

  interface FormElements
    extends HTMLFormControlsCollection,
      Record<QuestionId | 'position', HTMLInputElement> {}

  interface QuestionsFormElement extends HTMLFormElement {
    readonly elements: FormElements
  }

  const handleSubmit: svelte.JSX.FormEventHandler<QuestionsFormElement> = (
    event
  ) => {
    event.preventDefault()
    validationErrors = {}
    let encounteredErrors: Array<keyof AnswersState> = []

    const {
      position,
      goals,
      strength,
      formerPlayer,
      halfwayLine,
      afterTackle,
      breakaway,
      corner,
    } = $answers

    if (!position) {
      encounteredErrors.push('position')
      validationErrors.position = true
    }
    if (!goals) {
      encounteredErrors.push('goals')
      validationErrors = { ...validationErrors, goals: true }
    }
    if (!strength) {
      encounteredErrors.push('strength')
      validationErrors = { ...validationErrors, strength: true }
    }
    if (!formerPlayer) {
      encounteredErrors.push('formerPlayer')
      validationErrors = { ...validationErrors, formerPlayer: true }
    }
    if (!halfwayLine) {
      encounteredErrors.push('halfwayLine')
      validationErrors = { ...validationErrors, halfwayLine: true }
    }
    if (!afterTackle) {
      encounteredErrors.push('afterTackle')
      validationErrors = { ...validationErrors, afterTackle: true }
    }
    if (!breakaway) {
      encounteredErrors.push('breakaway')
      validationErrors = { ...validationErrors, breakaway: true }
    }
    if (!corner) {
      encounteredErrors.push('corner')
      validationErrors = { ...validationErrors, corner: true }
    }

    if (encounteredErrors.length) {
      const targetIndex = steps.indexOf(encounteredErrors[0])
      lastNavDirection = targetIndex < currentStepIndex ? 'back' : 'forward'
      currentStepIndex = targetIndex
      setTimeout(() => {
        try {
          const element = document.getElementById(
            `${encounteredErrors[0]}-group`
          )
          element.parentElement
            .querySelector('input')
            .focus({ preventScroll: true })
        } catch (error) {
          // ignore
        }
      }, 0)
      return
    }

    const answerList = Object.entries($answers).map(
      ([questionId, alternativeKey]: [QuestionId, string]) => ({
        questionId,
        alternativeKey,
      })
    )

    try {
      onResult(
        getTopPlayerBasedOnAnswers($answers.position as Position, answerList)
      )
    } catch (error) {
      Tracking.event('Quiz', 'SubmitFailed', { label: error?.message ?? 'N/A' })
    }
  }
</script>

<div class="min-h-[calc(100vh-9rem)] lg:min-h-[calc(100vh-15rem)] relative">
  <img
    src="/logos/compass.webp"
    alt="..."
    width="440"
    height="440"
    class={`compass ${currentStep}`}
  />
  <form
    class:isTransitioning
    class="absolute inset-0"
    bind:this={formNode}
    on:submit={handleSubmit}
  >
    {#each steps as _step, index}
      {#if index === currentStepIndex && currentStep !== 'outro'}
        <div
          in:flyIn|local
          out:flyOut|local
          on:introstart={handleTransitionStart}
          on:outrostart={handleTransitionStart}
          on:introend={handleTransitionEnd}
          on:outroend={handleTransitionEnd}
          class="absolute inset-0 flex flex-col"
        >
          <div class="w-full lg:w-3/5 my-auto p-4 pb-16 lg:pb-40 step-content">
            {#if currentStep === 'intro'}
              {#if isJerseyCompetitionActive}
                <h5 class="text-2xl font-medium">
                  Tävling! Vinn din spelares tröja.
                </h5>
                <p class="text-base max-w-prose mt-4 lg:pr-2">
                  Spelarkompassen hjälper dig att hitta en fotbollsspelare i
                  OBOS Damallsvenskan som liknar dig – någon som kan bli din
                  favorit. Nu kan du dessutom vinna den spelarens tröja!
                </p>
              {:else}
                <h5 class="text-2xl font-medium">
                  Vilken spelare är du mest lik?
                </h5>
                <p class="text-base max-w-prose mt-4">
                  Med Spelarkompassen hjälper vi dig att hitta en
                  fotbollsspelare i OBOS Damallsvenskan som liknar dig – en
                  spelare som kanske kan bli just din favorit, någon som visar
                  vägen.
                </p>
              {/if}
              <div class="mt-10 flex flex-col items-center lg:block">
                <SpecialButton size="large" on:click={handleForwardClick}>
                  Starta
                </SpecialButton>
              </div>
            {:else if currentStep === 'position'}
              <RadioGroup
                on:answer={handleAnswer}
                label="Din position"
                name="position"
                alternatives={positionAlternatives}
                error={validationErrors.position}
                value={$answers.position}
              />
            {:else}
              <RadioGroup
                on:answer={handleAnswer}
                label={currentQuestion.label}
                name={currentQuestion.id}
                alternatives={currentQuestion.alternatives}
                error={validationErrors[currentQuestion.id]}
                value={$answers[currentQuestion.id]}
              />
            {/if}
          </div>
        </div>
      {:else if currentStep === 'outro'}
        <div
          in:fade|local
          out:fade|local
          on:introstart={handleTransitionStart}
          on:outrostart={handleTransitionStart}
          on:introend={handleTransitionEnd}
          on:outroend={handleTransitionEnd}
          class="absolute inset-0 flex flex-col items-center justify-center lg:justify-end"
        >
          <div class="pt-6 lg:pt-0">
            <SpecialButton type="submit">Visa resultat</SpecialButton>
          </div>
        </div>
      {/if}
    {/each}
  </form>
  <div
    class="fixed bottom-0 left-0 w-full flex justify-end p-2 pointer-events-none"
  >
    <div class="space-x-2 pointer-events-auto">
      <button
        class="bg-black bg-opacity-10 hover:bg-opacity-50 focus:bg-opacity-50 rounded disabled:opacity-50 disabled:cursor-not-allowed px-1"
        disabled={!canGoBackward}
        type="button"
        on:click={handleBackClick}><ChevronUp /></button
      >
      <button
        class="bg-black bg-opacity-10 hover:bg-opacity-50 focus:bg-opacity-50 rounded disabled:opacity-50 disabled:cursor-not-allowed px-1"
        disabled={!canGoForward}
        type="button"
        on:click={handleForwardClick}><ChevronDown /></button
      >
    </div>
  </div>
</div>

<style>
  .isTransitioning {
    overflow-y: hidden;
  }

  @media (max-width: 600px) {
    .step-content {
      padding-top: 10rem;
    }
  }

  .compass {
    display: none;
    transition: transform 800ms ease;
    transform-origin: center;
    pointer-events: none;
  }

  @media (min-width: 600px) and (max-width: 1024px) and (max-aspect-ratio: 4/5) {
    .compass {
      display: block;
      width: 220px;
      height: 220px;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-4rem);
    }
  }

  @media (max-width: 600px) and (max-aspect-ratio: 4/5) {
    .compass {
      display: block;
      width: 220px;
      height: 220px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -4rem);
    }
    .intro {
      transform: translate(-50%, -4rem);
    }
    .position {
      transform: translate(-50%, -4rem) rotate(49deg);
    }
    .goals {
      transform: translate(-50%, -4rem) rotate(212deg);
    }
    .strength {
      transform: translate(-50%, -4rem) rotate(38deg);
    }
    .formerPlayer {
      transform: translate(-50%, -4rem) rotate(62deg);
    }
    .halfwayLine {
      transform: translate(-50%, -4rem) rotate(312deg);
    }
    .afterTackle {
      transform: translate(-50%, -4rem) rotate(224deg);
    }
    .breakaway {
      transform: translate(-50%, -4rem) rotate(92deg);
    }
    .corner {
      /* transform: initial; */
    }
    .outro {
      transition-duration: 1100ms;
      transform: translate(-50%, -4rem) rotate(366deg) scale(1.25);
    }
  }

  @media (min-width: 1024px) and (max-aspect-ratio: 11/5) {
    .compass {
      display: block;
      position: absolute;
      top: -4rem;
      right: 0;
    }

    .position {
      transform: rotate(139deg);
    }
    .goals {
      transform: rotate(212deg);
    }
    .strength {
      transform: rotate(38deg);
    }
    .formerPlayer {
      transform: rotate(62deg);
    }
    .halfwayLine {
      transform: rotate(312deg);
    }
    .afterTackle {
      transform: rotate(224deg);
    }
    .breakaway {
      transform: rotate(92deg);
    }
    .corner {
      transform: initial;
    }
    .outro {
      transition-duration: 900ms;
      transform: translate(-50%, 0) rotate(366deg) scale(1.2);
      right: initial;
      left: 50%;
    }
  }
</style>
