<script lang="ts">
  import { createEventDispatcher } from 'svelte'

  export let alternatives: Record<string, string>
  export let label: string
  export let name: string
  export let error = false
  export let value = ''

  $: labelId = `${name}-group`

  $: options = Object.entries(alternatives).map(([value, label]) => ({
    label,
    value,
  }))

  const dispatch = createEventDispatcher()

  const handleChange: svelte.JSX.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    dispatch('answer', {
      questionId: name,
      alternativeKey: event.currentTarget.value,
    })
  }
</script>

<div class={$$props.class} class:error>
  <h5 class="text-2xl font-medium mb-4 underline" id={labelId}>{label}</h5>
  {#each options as option, index}
    <div class="ml-1" role="radiogroup" aria-labelledby={labelId}>
      <input
        type="radio"
        id={`${name}-${option.value}`}
        {name}
        value={option.value}
        on:change={handleChange}
        checked={value === option.value}
      />
      <label for={`${name}-${option.value}`}>{option.label}</label>
    </div>
  {/each}
</div>

<style>
  .error h5::after {
    content: '*';
    margin-left: 0.125em;
    color: rgb(248, 113, 113);
  }

  h5 {
    scroll-margin-top: 1rem;
    text-decoration-thickness: 0.0625em;
  }

  [role='radiogroup'] + [role='radiogroup'] {
    margin-top: 0.25rem;
  }

  input {
    position: absolute;
    opacity: 0;
  }

  label {
    position: relative;
    cursor: pointer;
    display: inline-block;
    line-height: 1.5;
    font-size: 1.125rem;
    padding: 0;
    padding-left: 2.25rem;
    padding-top: 0.0625rem;
    padding-bottom: 0.0625rem;
  }

  label::before {
    content: '';
    position: absolute;
    top: 0.1875rem;
    left: 0;
    display: inline-block;
    border: thin solid white;
    border-radius: 100%;
    box-sizing: border-box;
    width: 1.125rem;
    height: 1.125rem;
    background-color: transparent;
  }

  input:checked + label:after {
    content: '';
    position: absolute;
    top: 0.5rem;
    left: 0.3125rem;
    width: 0.5rem;
    height: 0.5rem;
    background-color: white;
    border-radius: 100%;
  }

  input:disabled + label {
    cursor: not-allowed;
    opacity: 0.5;
  }

  input:active:enabled + label:before {
    border-color: white;
  }

  input:enabled:focus + label:before {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
</style>
