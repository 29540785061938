<script lang="ts">
	export let checked: boolean;
	export let disabled: boolean = false;
	export let error: boolean | string = false;
	export let id: string;
	export let name: string;
	export let value: string;
</script>

<div class="flex items-center">
	<input
		class="focus:ring-blue-600 h-4 w-4 text-blue-600 bg-transparent border-gray-300"
		type="checkbox"
		{checked}
		{disabled}
		{id}
		{name}
		{value}
		on:change
	/>
	<label class="ml-2" for={id}>
		<slot />
	</label>
</div>
{#if typeof error === 'string'}
	<span class="text-red-600">{error}</span>
{/if}
