<script lang="ts">
  export let size = '24'
</script>

<svg
  class={$$props.class}
  xmlns="http://www.w3.org/2000/svg"
  width={size}
  height={size}
  viewBox="0 0 24 24"
  fill="none"
  stroke="currentColor"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"><polyline points="18 15 12 9 6 15" /></svg
>
